
import React from 'react';
// import PropTypes from 'prop-types';

import './imagePreview.css';

export const ImagePreview = ({ dataUri }) => {
//   let classNameFullscreen = isFullscreen ? 'demo-image-preview-fullscreen' : '';
    console.log(dataUri)
  return (
      <div className="container">
        <div className='image-preview'>
            <img src={dataUri} alt="preview" style={{
                zIndex: '0'
                }}/>
        <div className="container">
            <div className="row" style={
                        {position: "absolute",
                        top: '2%',
                        // left: '50%',
                        zIndex: '2'
                }}>
                <div className="col-12 col-sm-6">
                    <button className="btn btn-secondary btn-sm">Retake</button>
                </div>
                <div className="col-12 col-sm-6">
                    <button className="btn btn-primary btn-sm">Use</button>
                </div>
            </div>
            
        </div>
    </div>
    </div>
  );
};

// ImagePreview.propTypes = {
//   dataUri: PropTypes.string,
//   isFullscreen: PropTypes.bool
// };

export default ImagePreview;