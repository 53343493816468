import React from 'react'

export const Contact = () => (
    <div>
        <h2> 
            Contact Us
        </h2>
        <p>How you contact us </p>
        
    </div>
)