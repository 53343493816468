import React from 'react';
import { Button, Card, Table } from 'react-bootstrap'



const SeaServiceRecords = () => (
    <React.Fragment>
        <Card>
            <Card.Header as="h2">Sea Service Records <span><Button className="btn-light btn-border float-right" >+</Button></span></Card.Header>
            <Card.Body>
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>
                                Vessel Name
                            </th>
                            <th>
                                Date Detached
                            </th>
                            <th>
                                Sea Service
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                SV Hope San
                            </td>
                            <td>
                                2020-02-20
                            </td>
                            <td>
                                30
                            </td>
                        </tr>
                        <tr>
                            <td>
                                SV San Hope
                            </td>
                            <td>
                                2019-01-01
                            </td>
                            <td>
                                01
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
        
    </React.Fragment>
)

export default SeaServiceRecords