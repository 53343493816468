import React from 'react';
import Card from 'react-bootstrap/Card';


const BigCard = (props) => (
    <React.Fragment>
        <Card style={{ width: '10rem'}} className="text-center">         
            <Card.Body>
            <Card.Title as="h1">{props.user.data.number}</Card.Title>
            <Card.Text>{props.user.data.description}</Card.Text>
            </Card.Body>
        </Card>
    </React.Fragment>
)

export default BigCard; 