import React, {useState} from 'react';


// This won't work correctly yet, it needs context to drive the states
export default ({label, type, ...props }) => {
    const {state} = useState();

    let classString;
    if (state === 'focused') {
        classString = 'usa-focus';
    }
    if (state === 'valid') {
        classString = 'usa-input--success';
    }
    
    switch(state) {
        case 'invalid':
            return (
                <>
                    {/* <div class="usa-alert usa-alert--info usa-alert--validation">
                        <div class="usa-alert__body">
                            <h3 class="usa-alert__heading">Code requirements</h3>
                            <ul class="usa-checklist" id="validate-code">
                                <li class="usa-checklist__item usa-checklist__item--checked" data-validator="uppercase">Use at least one uppercase character</li>
                                <li class="usa-checklist__item" data-validator="numerical">Use at least one number</li>
                            </ul>
                        </div>
                    </div> */}
                    <div className="usa-form-group usa-form-group--error">
                        <label className="usa-label usa-label--error" htmlFor={label}>{label}</label>
                        <span className="usa-error-message" id={`${label}-message`} role="alert">{props.errorMessage}</span>
                        <input className="usa-input usa-input--error" id={label} name={label} type="text" aria-describedby={`${label}-message`} />
                    </div>
                </>
            );
        default:
            return (
                <>
                    <label className="usa-label" htmlFor={label}>{label}</label>
                    <input className={`usa-input${classString}`} id={label} name={label} type="text"></input>
                </>
            )
    }
};