import React from 'react';
import {Text, Select} from 'components/fields';

// @TODO fix tonnage naming
class Vessel1 extends React.PureComponent {
    static initialValues = {
        tonnage: '',
        tonnageType: '',
        length_tonnage: '',
        width_tonnage: '',
        depth_tonnage: '',
    };

    render() {
        return (
            <fieldset>
                <legend>Your Vessel (3/4)</legend>
                <Text name="tonnage" label="What is the tonnage of this vessel?" />
                <Select name="tonnageType" label="Tonnage Type"
                    options={{
                        grt: 'Gross Registered Tonnage',
                        gt: 'Gross Tonnage',
                        nrt: 'National Registered Tonnage'
                    }}
                />
                <Text name="length_tonnage" label="How long is this vessel in Feet?" />
                <Text name="width_tonnage" label="How wide (Beam) is this vessel in Feet?" />
                <Text name="depth_tonnage" label="What is the draft of this vessel in Feet?" />
            </fieldset>
        )
    }
}

export default Vessel1;