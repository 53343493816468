import React from 'react';
import {NavLink} from 'react-router-dom';
import {Nav, Navbar, Button} from 'react-bootstrap';
import styled from 'styled-components';

import {LoginButton} from './LoginButton';


const Styles = styled.div`
    .navbar {
        background-color: #222;
    }

    a, .navbar-brand, .navbar-nav .nav-link {
        color: #bbb;

        &:hover {
            color: white;
            text-decoration: none !important;
        }
    }
    .nav-link.active {
        color: white;
        font-weight: bold;
    }
`;



 const NavBar = () => (
     <Styles>
        <Navbar expand="lg">
            <Navbar.Brand href="/">MCS</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">

                    <Nav.Item><Nav.Link as={NavLink} exact to="/">Home</Nav.Link></Nav.Item>
                    <LoginButton/>
                    <Nav.Item><Nav.Link as={NavLink} to="/contact">Contact</Nav.Link></Nav.Item>

                </Nav>
            </Navbar.Collapse>
            <Nav.Item><Nav.Link as={NavLink} to="/adddocs"><Button variant='light' >Add Sea Service</Button></Nav.Link></Nav.Item>
        </Navbar>
     </Styles>
 )

 export default NavBar

