import React from 'react';
import { NavLink } from 'react-router-dom';
import {Nav } from 'react-bootstrap';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import { Folder } from 'react-bootstrap-icons';
// import { Clipboard } from 'react-bootstrap-icons';
import { Greeting } from '../components/Greeting';
import AnchorPng from '../assets/anchor-sm.png';
import FolderPng from '../assets/folder-icon-sm.png';
import DocumentPng from '../assets/document-icon-sm-2.png';
import ClipboardPng from '../assets/clipboard-icon-sm.png';
import CogPng from '../assets/cog-icon-sm.png';
import styled from 'styled-components';
// import '../../src/assets/styles/sidebar.css'


const Styles = styled.div`

    .icon {
        max-width:15px;
        background-color:blue;
    }

    .nav-link {
        padding-left:10px;
    }

`


export const LeftNav = (props) => (
    <React.Fragment>
        <Styles>
            <Greeting user={props.user} />
            <hr/>
            <Nav className="col-md-12 d-none d-md-block bg-light sidebar"
                activeKey="/"
                onSelect={selectedKey => alert(`selected ${selectedKey}`)}
            >
                <div className="sidebar-sticky"></div>
            <Nav.Item>
                <Nav.Link as={NavLink} to="/seaservice"> <img src={AnchorPng} alt="Anchor icon"/> Sea Service</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} to="/training"> <img src={DocumentPng} alt="Training icon"/> Training </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} to="/documents"> <img src={FolderPng} alt="Documents icon" /> Documents</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} to="/applications"> <img src={ClipboardPng} alt="Applications icon" /> Applications</Nav.Link>
            </Nav.Item>
            <p className='muted'>Settings</p>
            <Nav.Item>
                <Nav.Link as={NavLink} to="/settings"> <img src={CogPng} alt="Settings icon" /> User Settings</Nav.Link>
            </Nav.Item>
            </Nav>
        </Styles>
    </React.Fragment>
)
