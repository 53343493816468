import React from 'react'
import BigCard from './components/BigCard'

const user = {data:
    {number:568,
    description: 'Total Sea Days'
    }
}


const Seaservice = () => (
    <div>
        <h2 className="display-4"> 
            Sea Service Summary
        </h2>
        <BigCard user={user} />
        <p>This is where we'd talk about a user's Seaservice, or cats </p>
        
    </div>
)

export default Seaservice