import React from 'react'
import './fileUploadWindow.css';


export const FileUploadWindow = ({onFileChange}) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <form method="post" action="#" id="#">
                    
                        
                        <div className="form-group files">
                            <label muted>Select or Drop File </label>
                            <input type="file" name="file" className="form-control" multiple="" onChange={onFileChange}/>
                        </div>
                    </form>
                    
                </div>
            </div>
        </div>
    )
}

