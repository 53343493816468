import React, { Component } from 'react';
import {BrowserRouter} from 'react-router-dom';


import Layout from './components/Layout';
import NavBar from './components/NavBar';
import { Routes } from './Routes';
import { Jumbotron } from './components/Jumbotron';
import { UserProvider } from './context/UserState';



class App extends Component {
  render() {
    return (
      <UserProvider>
        <BrowserRouter>
          <NavBar />
          <Jumbotron />        
          <Layout>            
            <Routes/>
          </Layout>
        </BrowserRouter>
      </UserProvider>
    );
  }
}

export default App;
