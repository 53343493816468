import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { LeftNav } from './LeftNav';


const Styles = styled.div`
    .leftNav {
        min-width: 200px;
    }

`

const user = {
    name:'Nate',
    height: '5.5'
}

const Layout = (props) => (
    <React.Fragment>
        <Styles>
            <Container fluid>
                <Row>
                    <Col xs={2} className="leftNav">
                        <LeftNav user={user}/>
                    </Col>
                    <Col md='auto'>
                        {props.children}
                    </Col>
                </Row>
            </Container>
        </Styles>
    </React.Fragment>

)

export default Layout;
