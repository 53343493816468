import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Link,
    useLocation
  } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { StateMachineProvider, createStore } from "little-state-machine";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Result from "./Result";

import "./styles.css";

createStore({
    yourDetails: {
      firstName: "",
      lastName: "",
      age: "",
      yearsOfExp: ""
    }
  });

const Pages = () => {
    const location = useLocation();

    return (
      <>
        <nav className="container">
          <div className="steps btn-group" role="group">
            <button className={((location.pathname === "/") || (location.pathname === "/adddocs")) ? "active" : ""}>
              <Link to="/">Step 1</Link>
            </button>
            <button className={location.pathname === "/step2" ? "active" : ""}>
              <Link to="/step2">Step 2</Link>
            </button>
            <button className={location.pathname === "/result" ? "active" : ""}>
              <Link to="/result">Result</Link>
            </button>
          </div>
        </nav>
        <Route exact path="/" component={Step1} />
        <Route path="/adddocs" component={Step1} />
        <Route path="/step2" component={Step2} />
        <Route path="/result" component={Result} />
      </>
    );
  };

function Form719sModal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <StateMachineProvider>
            <Button variant="secondary" onClick={handleShow}>
                Enter in a CG719S Form
            </Button>
            <Modal 
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>CG719S Form</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <Router>
                        <Pages />
                    </Router>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
        </StateMachineProvider>
    );
}
export default Form719sModal;