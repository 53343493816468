import React from 'react';
import DatePicker from 'uswds/src/js/components/date-picker';

export default class Date extends React.Component {
    constructor(props) {
        super(props);
        this.me = React.createRef();
    }

    componentDidMount() {
        // This is really funky, lets swap this to the truss version once they have it
        this.setState({
            datePicker: DatePicker.init(this.me.current)
        }, () => {
            DatePicker.add(this.me.current);
        });
    }

    render() {
        return (
            <div className="usa-form-group" ref={this.me}>
                {/* <div class="usa-alert usa-alert--info usa-alert--validation">
                    <div class="usa-alert__body">
                        <h3 class="usa-alert__heading">Code requirements</h3>
                        <ul class="usa-checklist" id="validate-code">
                        <li class="usa-checklist__item usa-checklist__item--checked" data-validator="uppercase">Use at least one uppercase character</li>
                        <li class="usa-checklist__item" data-validator="numerical">Use at least one number</li>
                        </ul>
                    </div>
                </div> */}
                <label className="usa-label" id={`${this.props.name}-label`} htmlFor={this.props.name}>{this.props.label}</label>
                <div className="usa-hint" id={`${this.props.name}-hint`}>{this.props.hint || 'mm/dd/yyyy'}</div>
                <div className="usa-date-picker">
                    <input 
                        className="usa-input" 
                        id={this.props.name} 
                        name={this.props.name} 
                        type="text" 
                        aria-describedby={`${this.props.name}-label ${this.props.name}-hint`}
                    />
                </div>
            </div>
        )
    }
}