import React from 'react';
import {Select} from 'components/fields';

class Vessel4 extends React.PureComponent {
    static initialValues = {
        engine_mode_of_propulsion: '',
    };

    render() {
        return (
            <fieldset>
                <legend>Your Vessel (4/4)</legend>
                <Select 
                    name="engine_mode_of_propulsion" 
                    label="What type of propulsion does this vessel use?"
                    options={{
                        motor: 'Motor',
                        steam: 'Steam',
                        gasturbine: 'Gas Turbine'
                    }}
                />
            </fieldset>
        )
    }
}

export default Vessel4;