export default (state, action) => {
    switch(action.type) {
        case 'LOGIN_SUCCESSFUL':
            return {
                ...state,
                token: action.payload.token,
                user: {...action.payload.user, 
                        loggedIn: true}

            }
        
        case 'LOGOUT':
            return {
                ...state,
                user: {loggedIn: false}
            }

        case 'ADD_COURSE':
            return {
                ...state,
                user: state.user
            }
        case 'DELETE_COURSE':
            return {
                ...state,
                user: { ...state.user,
                        courses: state.user.courses.filter(course => course.id !== action.payload)}
            }

        default:
            return state;

    }
}
