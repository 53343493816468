import React from 'react';
import {Checkbox} from 'components/fields';

class Owner extends React.PureComponent {
    static initialValues = {
        owner_operator_or_master: false
    };

    render() {
        return (
            <fieldset>
                <legend>Vessel Ownership</legend>
                <Checkbox name="owner_operator_or_master" label="Are you the Owner, Operator, or Master of the vessel?" />
            </fieldset>
        )
    }
}

export default Owner;