import React, { useState, useContext } from 'react'
import {Form, Button } from 'react-bootstrap'
import {UserContext} from './context/UserState';

const initialFormData = {
    email:"",
    username: "",
    password:""
}

export const Login = () => {
    const { user, logIn, token } = useContext( UserContext )

    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            // Trim whitespace
            [e.target.name]: e.target.value.trim()
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('hit function')
        console.log(formData)
        logIn(formData)
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                {/* <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" name="email" onChange={handleChange}/>
                    <Form.Text className="text-muted" >
                    We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group> */}

                <Form.Group controlId="formUsername">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="username" placeholder="Enter username" name="username" onChange={handleChange}/>
                    <Form.Text className="text-muted" >
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" name="password" onChange={handleChange}/>
                </Form.Group>
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Check me out" />
                </Form.Group>
                <Button variant="primary" type="submit" >
                    Submit
                </Button>
            </Form>
            <h3> Current User: {user.firstname} </h3>
            <h5> token: {token} </h5>

            
        </div>
    )
}
