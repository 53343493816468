import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';
// import CgForm from './cgform/CgForm';
import Form719sModal from './cg719s/FormModal';
import { DocumentUpload } from './DocUpload/DocumentUpload';



const AddDocs = () => {
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    
    return (

    <React.Fragment>
      <Form719sModal />
      <br />
      <DocumentUpload show={show} setShow={setShow} />
      <Button onClick={handleShow}>Upload Document</Button>

    </React.Fragment>

    );
}

export default AddDocs;