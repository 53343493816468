
import React from 'react'
import {Route, Switch} from 'react-router-dom';


import { Home } from './Home';
import Seaservice from './Seaservice';
import { Contact } from './Contact';
import AddDocs from './components/AddDocs';
import { Login } from './Login'
import { NoMatch } from './NoMatch';
import {Form719S} from './forms';


export const Routes = () => {

    return (
    <Switch>
        <Route exact path = "/" component={Home} />
        <Route path = "/seaservice" component={Seaservice} />
        <Route path = "/contact" component={Contact} />
        <Route path = "/adddocs" component={AddDocs} />
        <Route path = "/login" component={Login} />
        <Route path='/form/719s' component={Form719S} />
        <Route component={NoMatch} />
    </Switch>
    )
}