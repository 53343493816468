import React from 'react';
// import { ObjectFlags } from '../../../node_modules/typescript/lib/typescript';

export default ({ label, ...props }) => {
    /**
     * Generate <options> given an object of the shape:
     * {
     *   value: 'Display Name',
     *   'complex value 23': 'Godzilla'
     * }
     */
    let options = [];
    for (const [value, displayName] of Object.entries(props.options)) {
        options.push(<option key={label + '-' + value} value={value}>{displayName}</option>)
    }

    return (
        <>
            {/* <div class="usa-alert usa-alert--info usa-alert--validation">
                <div class="usa-alert__body">
                    <h3 class="usa-alert__heading">Code requirements</h3>
                    <ul class="usa-checklist" id="validate-code">
                    <li class="usa-checklist__item usa-checklist__item--checked" data-validator="uppercase">Use at least one uppercase character</li>
                    <li class="usa-checklist__item" data-validator="numerical">Use at least one number</li>
                    </ul>
                </div>
            </div> */}
            <label className="usa-label" htmlFor={props.name}>{label}</label>
            <select className="usa-select" name={props.name} id={props.name}>
                {options}
            </select>
        </>
    );
};