import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import CameraUpload from './CameraUpload';
// import FileUploadBar from './FileUploadBar';
import { FileUploadWindow } from './FileUploadWindow';
import ImagePreview from './ImagePreview';

export const DocumentUpload = ({show, setShow}) => {

    const [cameraShow, setCameraShow] = useState(false)
    const [cameraUri, setCameraUri] = useState("")
    const [fileUploadShow, setFileUploadShow] = useState(true)
    const [selectedFile, setSelectedFile] = useState(false)
    const [showPreview, setShowPreview] = useState(false)

    const handleClose = () => {
        setShow(false);
        setCameraShow(false);
    }
    // const handleShow = () => setShow(true);

    const handleCameraShow = () => {
        setCameraShow(true)
        setFileUploadShow(false)
    }
    // const handleCameraClose = () => setCameraShow(false)

    const handlePicture = (uri) => {
        setCameraShow(false);
        setCameraUri(uri);
        setShowPreview(true);

    }

    const onFileChange = (e) => {
        console.log('File Change')
        setSelectedFile(e.target.files[0])
    }


    const FileData = () => {
        if(selectedFile) {
            return (
                <React.Fragment>
                    <p>FileName: {selectedFile.name} </p>
                    <p>File Type: {selectedFile.type} </p>
                </React.Fragment>
            )
        } else if(fileUploadShow) {
            return (
                <React.Fragment>
                    <FileUploadWindow onFileChange={onFileChange} />
                </React.Fragment>
            )
        } else if(showPreview) {
            return (
                <React.Fragment>
                    <ImagePreview dataUri={cameraUri}/>
                </React.Fragment>
            ) 
        } else {
            return <React.Fragment></React.Fragment>
        }
    }


    return (
        <div>
            <Modal 
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >

                <Modal.Header closeButton>
                    <Modal.Title>Document Upload</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <div>
                        <FileData />
                        <CameraUpload cameraShow={cameraShow} handlePicture={handlePicture}/> 
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" 
                        onClick={handleClose}>
                        Close
                    </Button>

                {/* <FileUploadBar/> */}
                <Button onClick={onFileChange}>Upload File </Button>
                
                <Button onClick={handleCameraShow}>Camera</Button>
                </Modal.Footer>
            </Modal>
            
        </div>
    )
}

