import React from 'react';
import { Jumbotron as Jumbo } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import boatImage from '../assets/boat-image2.jpg'

const Styles = styled.div`

    .jumbo {
        background: url(${boatImage}) no-repeat fixed bottom;
        background-size: cover;
        color: #ccc;
        height: 200px;
        position: relative;
        z-index: -2;
    }

    .overlay {
        background-color: #000;
        opacity: 0.6;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;


    }





`;

export const Jumbotron = () => (
    <Styles>
        <Jumbo fluid className="jumbo"> 
        <div className="overlay"></div>
        <Container>
            <h1>Welcome to MCS</h1>
        </Container>

        </Jumbo>

    </Styles>

) 

