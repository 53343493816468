import React from 'react';
import {Text, Select, Checkbox} from 'components/fields';

class Vessel1 extends React.PureComponent {
    static initialValues = {
        vessel_name: '',
        flag: '',
        stcw_applies: false,
    };

    render() {
        return (
            <fieldset>
                <legend>Your Vessel (1/4)</legend>
                <Text name="vessel_name" label="Vessel Name" />
                <Select name="flag" label="What flag is the vessel registered under?"
                    options={{
                        US: 'United States',
                        CA: 'Canada',
                        MX: 'Mexico'
                    }}
                />
                <Checkbox name="stcw_applies" label="Does this vessel fall under STCW compliance rules?" />
            </fieldset>
        )
    }
}

export default Vessel1;