import React from 'react'
import userIcon from '../assets/user-icon-sm.png'


export const Greeting = (props) => (
    <React.Fragment>
        <div> 
            <img src={userIcon} className="img-fluid" alt='user icon' />
            <h3> Welcome Back <br />
                <b>{props.user.name}</b>
            </h3>
        </div>
    </React.Fragment>
)
