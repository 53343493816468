import React from 'react';
import {Text} from 'components/fields';

class Vessel2 extends React.PureComponent {
    static initialValues = {
        imo_number: '',
        mmsi: '',
        uscg_official_number: '',
        state_registration: '',
        other_registration_number: '',
    };

    render() {
        return (
            <fieldset>
                <legend>Your Vessel (2/4)</legend>
                <Text name="imo_number" label="IMO Number" />
                <Text name="mmsi" label="MMSI" />
                <Text name="uscg_official_number" label="Official USCG documentation number" />
                <Text name="state_registration" label="State Registration Number" />
                <Text name="other_registration_number" label="Other Registration Number" />
            </fieldset>
        )
    }
}

export default Vessel2;