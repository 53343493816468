import React, {Component} from 'react';

class MCSForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            section: 0
        };
    }
    
    handlePrevious = event => {
        this.setState(state => ({
            section: state.section - 1
        }));
        event.preventDefault();
    };
    
    handleNext = event => {
        this.setState(state => ({
            section: state.section + 1
        }));
        event.preventDefault();
    };
    
    getInitialValues = () => {
        return this.props.formSections.reduce((accumulator, section) => {
            return {
                ...accumulator,
                ...section.initialValues
            }
        }, {});
    }

    renderSection = () => {
        return React.createElement(this.props.formSections[this.state.section]);
    }

    render() {
        return (
            <div className="o-form">
                <h1 className="o-form-title">{this.props.title}</h1>
                <form>
                    {this.renderSection()}
                    <div className="o-full-width">
                        <button className="usa-button usa-button--outline" onClick={this.handlePrevious} disabled={this.state.section < 1}>
                            Back
                        </button>
                        {
                            this.state.section < this.props.formSections.length - 1
                                ? (
                                    <button className="usa-button usa-button--base" onClick={this.handleNext}>
                                        Next
                                    </button>
                                ) : null
                        }
                        {
                            this.state.section === this.props.formSections.length - 1
                                ? (
                                    <button className="usa-button" onClick={this.handleNext}>
                                        Save
                                    </button>
                                ) : null
                        }
                    </div>
                </form>
            </div>
        );
    }
}

export default MCSForm;