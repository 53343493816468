import React from 'react';
import {Text, Checkbox} from 'components/fields';

class General extends React.PureComponent {
    static initialValues = {
        lastName: '',
        firstName: '',
        middleName: '',
        referenceNum: '',
        ssnIncluded: false
    };

    render() {
        return (
            <fieldset>
                <legend>Your Information</legend>
                <Text name="firstName" label="First Name" />
                <Text name="middleName" label="Middle Name" />
                <Text name="lastName" label="Last Name" />
                <Text name="referenceNum" label="Reference Number" />
                <Checkbox name="ssnIncluded" label="SSN Included?" />
            </fieldset>
        )
    }
}

export default General;