import React from 'react';
import {Checkbox, Date as DateField} from 'components/fields';

class Signature extends React.PureComponent {
    static initialValues = {
        signed: false,
        date_document_signed: ''
    };

    render() {
        return (
            <fieldset>
                <legend>Signature</legend>
                <Checkbox name="signed" label="Is this CG-719S form signed by the person attesting to this experience?" />
                <DateField name="date_document_signed" label="What date was the document signed?" />
            </fieldset>
        )
    }
}

export default Signature;