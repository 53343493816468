import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';

const initialState = {
    user: {
        loggedIn: false,
        firstname: "",
        lastname:"",

        courses: [],
        user_sea_services: [],

    },
    token: "",

}


// const loggedInState = {
//     user: {
//         loggedIn: true,
//         firstname: "",
//         lastname:"",

//         courses: [
//             {id: 1, text: "fake course 1"},
//             {id: 22, text: "second fake course"},
//         ],
//         user_sea_services: [
//             {id: 24, text: "user sea time 1:"}
//         ]
//     },
//     token: "newtoken",

// }




// Creat Context 
export const UserContext = createContext(initialState);

// Provider component
export const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);

    // Actions
    function deleteCourse(id) {
        dispatch({
            type: 'DELETE_COURSE',
            payload: id
        })
    }

    function logIn(user_login_info) {
        fetch("/api/user/signin", {
            method: "POST",
            // mode: 'no-cors',
            
            headers: {
              'Content-Type': 'application/json',
              'accept': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(user_login_info) // body data type must match "Content-Type" header
          })
          .then(res => res.json())
          .then(
            (result) => {
            //   setIsLoaded(true);
            //   setItems(result.items);
            console.log(result)  
            dispatch({
                type: 'LOGIN_SUCCESSFUL',
                payload: result
                })
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                dispatch({
                    type: 'LOGOUT',
                    payload: error
                })    
            //   setIsLoaded(true);
            //   setError(error);
            }
        );
    }
        

    return (
        <UserContext.Provider value={{
            user: state.user,
            token: state.token,
            deleteCourse,
            logIn
        }}>
            { children }
        
        </UserContext.Provider>
    )}