import React from 'react';
import {Checkbox} from '@trussworks/react-uswds';

export default ({ children, ...props }) => {
    return (
        <>
            {/* <div class="usa-alert usa-alert--info usa-alert--validation">
                <div class="usa-alert__body">
                    <h3 class="usa-alert__heading">Code requirements</h3>
                    <ul class="usa-checklist" id="validate-code">
                    <li class="usa-checklist__item usa-checklist__item--checked" data-validator="uppercase">Use at least one uppercase character</li>
                    <li class="usa-checklist__item" data-validator="numerical">Use at least one number</li>
                    </ul>
                </div>
            </div> */}
            <Checkbox id={props.name} {...props} />
        </>
    );
};
  