import React from 'react'
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import './imagePreview.css';
 
function CameraUpload ({cameraShow, handlePicture}) {
  function handleTakePhoto (dataUri) {
    // Do stuff with the photo...

    console.log('takePhoto');
    // console.log(dataUri)
    handlePicture(dataUri)
  }

  if(cameraShow) {
 
  return (
    
    <Camera
      onTakePhotoAnimationDone = { (dataUri) => { handleTakePhoto(dataUri); } }
      isFullscreen = {false}
      idealResolution = {{width: 640, height: 480}}
      sizeFactor = {1}
    />
  )} else {
  return (
    <React.Fragment></React.Fragment>
  )
  }

}
 
export default CameraUpload;