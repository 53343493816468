import React, { useContext } from 'react'
import {Nav} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import { UserContext } from '../context/UserState';


export const LoginButton = () => {
    const {user} = useContext(UserContext)

    if (user.loggedIn) {
        return (
            <div>
                <Nav.Item><Nav.Link as={NavLink} to="/logout">Logout</Nav.Link></Nav.Item>
            </div>

        )}
        return (
            <div>
                <Nav.Item><Nav.Link as={NavLink} to="/login">Login</Nav.Link></Nav.Item>
            </div>

        )
}
