import React from 'react';
import {
    Signature,
    Owner,
    General,
    Vessel1,
    Vessel2,
    Vessel3,
    Vessel4,
} from './sections';
import Form from './form';

class Form719S extends React.Component {
    render() {
        return (
            <Form className="form-719-s"
                title="CG-719S"
                formSections={[
                    Signature,
                    Owner,
                    General,
                    Vessel1,
                    Vessel2,
                    Vessel3,
                    Vessel4
                ]} />
        );
    }
}

export default Form719S;